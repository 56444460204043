html, body, #root, .App {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Estedad',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding-right: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Estedad';
  font-weight: 400;
  src: url('/assets/Estedad-Regular.ttf') format('truetype');
}


@font-face {
  font-family: 'Estedad';
  font-weight: 300;
  src: url('/assets/Estedad-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Estedad';
  font-weight: 200;
  src: url('/assets/Estedad-ExtraLight.ttf') format('truetype');
}
